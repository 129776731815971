import React from 'react';
import { DataTypeToggle } from './DataTypeToggle';
import { CountryListItem } from './CountryListItem';
import { HealthMapLogo } from 'src/components/Logos';
import { LocationData, DataType } from '../Map/types';
import * as Styled from './styles';

interface Props {
  dataType: DataType;
  countriesData: LocationData[];
  onListItemClick: (countryId: string) => void;
  onToggleDataType: (dataType: DataType) => void;
}

export const Sidebar: React.FC<Props> = ({
  dataType,
  onListItemClick,
  onToggleDataType,
  countriesData,
}) => {
  const getCountryList = (dataType: DataType) => {
    const sortedData = countriesData.sort(
      (a, b) => b[`${dataType}`] - a[`${dataType}`]
    );
    const list = sortedData.map(country => (
      <CountryListItem
        key={country.location_id}
        country={country}
        onClick={onListItemClick}
        dataType={dataType}
      />
    ));
    return list;
  };

  return (
    <Styled.SidebarContainer>
      <HealthMapLogo />
      <Styled.Header>
        <Styled.HeaderPrimary>COVID-19</Styled.HeaderPrimary>
        <Styled.HeaderSecondary>Omicron Variant</Styled.HeaderSecondary>
      </Styled.Header>
      <DataTypeToggle
        dataType={dataType}
        onToggleClick={onToggleDataType}
      />
      <Styled.CountryList data-test="country-list">
        {getCountryList(dataType)}
      </Styled.CountryList>
    </Styled.SidebarContainer>
  );
};
