import styled from 'styled-components';

export const ListItem = styled.li`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: .5rem;
`;

export const Color = styled.span`
  width: .75rem;
  height: .75rem;
  background-color: ${(props) => props.color};
`;

export const Label = styled.span`
  font-size: .6rem;
  @media (min-width: 768px) {
    font-size: .75rem;
  }
`;
