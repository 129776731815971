import { DataType, DataTypes, LocationType, MapSource, MapLayer, MatchExpressionDataKey, MatchExpressionLookupKey } from './types';
import { MapboxOptions } from 'mapbox-gl';

export const LOCATIONS_CASE_COUNTS_API =
  'https://newsnodes.com/_auto/API_omicron_v2/';

const MAP_STYLE = 'mapbox://styles/healthmap/ckx9vflbq0ok715mf7uu0ff6m';
const MAP_INITIAL_LON = 0;
const MAP_INITIAL_LAT = 40;
const MAP_INITIAL_ZOOM = 1;

export const MOBILE_MAP_HEIGHT = '50vh';

export const mapConfig: MapboxOptions = {
  container: 'map',
  style: MAP_STYLE,
  center: [MAP_INITIAL_LON, MAP_INITIAL_LAT],
  zoom: MAP_INITIAL_ZOOM,
};

export const mapSources: MapSource[] = [
  {
    id: LocationType.COUNTRIES,
    options: {
      type: 'vector',
      url: 'mapbox://mapbox.country-boundaries-v1',
    }
  },
  {
    id: LocationType.STATES,
    options: {
      type: 'geojson',
      data: 'https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson'
    }
  }
];

export const mapLayers: MapLayer[] = [
  {
    id: `${LocationType.COUNTRIES}_${DataType.CONFIRMED}`,
    dataType: DataType.CONFIRMED,
    locationType: LocationType.COUNTRIES,
    matchExpressionLookupKey: MatchExpressionLookupKey.COUNTRIES,
    matchExpressionDataKey: MatchExpressionDataKey.COUNTRIES,
  },
  {
    id: `${LocationType.STATES}_${DataType.CONFIRMED}`,
    dataType: DataType.CONFIRMED,
    locationType: LocationType.STATES,
    matchExpressionLookupKey: MatchExpressionLookupKey.STATES,
    matchExpressionDataKey: MatchExpressionDataKey.STATES
  },
  {
    id: `${LocationType.COUNTRIES}_${DataType.PREVALENCE}`,
    dataType: DataType.PREVALENCE,
    locationType: LocationType.COUNTRIES,
    matchExpressionLookupKey: MatchExpressionLookupKey.COUNTRIES,
    matchExpressionDataKey: MatchExpressionDataKey.COUNTRIES,
  },
  {
    id: `${LocationType.STATES}_${DataType.PREVALENCE}`,
    dataType: DataType.PREVALENCE,
    locationType: LocationType.STATES,
    matchExpressionLookupKey: MatchExpressionLookupKey.STATES,
    matchExpressionDataKey: MatchExpressionDataKey.STATES
  }
];

export const dataTypes: DataTypes = {
  [DataType.CONFIRMED]: {
    label: 'Confirmed Cases',
    ranges: [
      {
        MIN: 1,
        MAX: 50,
        LABEL: '< 50',
        COLOR: '#88d0eb',
      },
      {
        MIN: 51,
        MAX: 100,
        LABEL: '50-100',
        COLOR: '#64c6f0',
      },
      {
        MIN: 101,
        MAX: 500,
        LABEL: '100-500',
        COLOR: '#51beec',
      },
      {
        MIN: 501,
        MAX: 1000,
        LABEL: '500-1k',
        COLOR: '#29b1ea',
      },
      {
        MIN: 1001,
        MAX: 5000,
        LABEL: '1k-5k',
        COLOR: '#0093e4',
      },
      {
        MIN: 5001,
        MAX: 10000,
        LABEL: '5k-10k',
        COLOR: '#0074ab',
      },
      {
        MIN: 10001,
        LABEL: '10k+',
        COLOR: '#225893',
      },
    ]
  },
  [DataType.PREVALENCE]: {
    label: 'Prevalence',
    dataSuffix: '%',
    ranges: [
      {
        MIN: 1,
        MAX: 20,
        LABEL: '< 20',
        COLOR: '#88d0eb',
      },
      {
        MIN: 21,
        MAX: 40,
        LABEL: '20-40',
        COLOR: '#51beec',
      },
      {
        MIN: 41,
        MAX: 60,
        LABEL: '40-60',
        COLOR: '#29b1ea',
      },
      {
        MIN: 61,
        MAX: 80,
        LABEL: '60-80',
        COLOR: '#0093e4',
      },
      {
        MIN: 81,
        MAX: 100,
        LABEL: '80-100',
        COLOR: '#225893',
      },
    ]
  }
};
export const NO_DATA_COLOR = '#d8e8ec';
