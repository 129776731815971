import styled from 'styled-components';
import { footerHeight } from 'src/components/Footer/styles';

export const SidebarContainer = styled.div.attrs({
  id: 'sidebar-container',
})`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 96px;
  box-shadow: 0 -10px 12px -9px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  z-index: 1000;
  @media (min-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    width: 20rem;
    height: calc(100vh - ${footerHeight});
    border-radius: 0;
    box-shadow: 10px -16px 30px 0px rgb(0 0 0 / 10%);
  }
`;

export const Header = styled.h1`
  margin: 0 0 2rem;
  font-weight: normal;
`;

export const HeaderPrimary = styled.span`
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #dedede;
`;

export const HeaderSecondary = styled.span`
  display: block;
  margin-top: 1rem;
  font-size: 1.5rem;
`;

export const CountryList = styled.ul`
  overflow: auto;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  /* add spacing between scrollbar and content */
  padding-right: 20px;
  margin-right: -20px;
`;
