import styled from 'styled-components';

export const CountryListItem = styled.li`
  border-bottom: 1px solid #ececec;
`;

export const CountryButton = styled.button`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0.75rem;
  text-align: left;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:focus,
  &:hover,
  &:active {
    background-color: #f3f3f3;
  }
`;

export const CountryName = styled.div``;

export const CountryCount = styled.div`
  color: #666;
`;
