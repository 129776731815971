import { HomeLayout } from './layouts/HomeLayout';
import { MapboxMap } from './components/Map';
import { Footer } from './components/Footer';

function App() {
  return (
    <HomeLayout>
      <MapboxMap />
      <Footer />
    </HomeLayout>
  );
}

export default App;
