import React from 'react';

import { DataType } from '../../Map/types';
import { dataTypes } from '../../Map/constants';
import * as Styled from './styles';

interface Props {
  dataType: DataType;
  onToggleClick: (dataType: DataType) => void;
}

export const DataTypeToggle: React.FC<Props> = ({
  dataType,
  onToggleClick,
}) => {
  return (
    <Styled.ButtonGroup data-test="data-type-toggle">
      <Styled.ToggleButton
        isActive={dataType === DataType.CONFIRMED}
        onClick={() => onToggleClick(DataType.CONFIRMED)}
      >
        {dataTypes[DataType.CONFIRMED].label}
      </Styled.ToggleButton>
      <Styled.ToggleButton
        isActive={dataType === DataType.PREVALENCE}
        onClick={() => onToggleClick(DataType.PREVALENCE)}
      >
        {dataTypes[DataType.PREVALENCE].label}
      </Styled.ToggleButton>
    </Styled.ButtonGroup>
  );
};
