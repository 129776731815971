import * as constants from './constants';
import { LngLatLike, Expression, Map } from 'mapbox-gl';
import { DataTypes, LocationData, DataType, CountryGeoData } from './types';
import { countryGeoData } from './countryGeoData';

const inRange = (num:number, min:number, max?:number) => num >= min && (!max || num <= max);

export const getRangeColor = (num:number, caseType:string) => {
  const rangeMatch = constants.dataTypes[caseType as keyof DataTypes].ranges.find(range => inRange(num, range.MIN, range?.MAX));
  return rangeMatch?.COLOR ?? constants.NO_DATA_COLOR;
};

export const buildColorRange = (
  locationData: LocationData[],
  dataType: DataType,
  matchLookupKey: string,
  dataLookupKey: string,
) => {
  const fillColors: Expression = ['match', ['get', matchLookupKey]];
  for (const row of locationData) {
    const rangeColor = getRangeColor(
      row[`${dataType}`],
      dataType
    );
    fillColors.push(row[dataLookupKey as keyof LocationData], rangeColor);
  }
  // default color for locations with no data
  fillColors.push(constants.NO_DATA_COLOR);
  return fillColors;
};

export const getCountryGeoData = (countryId:string) =>
    countryGeoData.find((country) => country.id===countryId);

export const getBoundingBox = (country:CountryGeoData) => {
  if (country.centroid && country.centroid.length === 2) {
    // find the first (probably only) bb that contains the country centroid.
    const lat = country.centroid[0];
    const lon = country.centroid[1];
    const enclosingBBs = country.boundingBoxes.filter((box) => {
      return lat > box[1] && lat < box[3] && lon > box[0] && lon < box[2];
    });
    if (enclosingBBs.length > 0) {
      return enclosingBBs[0];
    }
  }
  // Assume the 'main' geographical region is listed first.
  return country.boundingBoxes[0];
};

export const flyToCountry = (countryId:string, map:Map|undefined) => {
  const country = getCountryGeoData(countryId);
  if (country) {
    const boundingBox = getBoundingBox(country);
    const southWestCorner:LngLatLike = [boundingBox[0], boundingBox[1]];
    const northEastCorner:LngLatLike = [boundingBox[2], boundingBox[3]];
    map?.fitBounds([southWestCorner, northEastCorner]);
  }
};