import React from 'react';
import * as Styled from './styles';

export const Loader: React.FC = () =>
  <>
    <Styled.LoaderBackground />
    <Styled.LoaderContainer>
      <Styled.LoaderRing>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Styled.LoaderRing>
    </Styled.LoaderContainer>
  </>
;