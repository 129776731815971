import styled from 'styled-components';

export const legendMobileHeight = '96px';

export const Legend = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  padding-bottom: 0.75rem;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(0.5rem);
  -webkit-backdrop-filter: blur(0.5rem);
  box-shadow: 0 10px 30px 1px rgb(0 0 0 / 10%);
  @media (min-width: 768px) {
    z-index: 3;
    left: auto;
    right: 1rem;
    top: auto;
    bottom: 2rem;
    width: auto;
    max-width: 6rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
`;

export const Heading = styled.p`
  margin: 0 0 0.25rem;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.4;
  text-align: center;
  font-weight: normal;
  @media (min-width: 768px) {
    margin: 0 0 0.5rem;
    font-size: 0.75rem;
    font-weight: bold;
  }
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  @media (min-width: 768px) {
    flex-direction: column;
  }
`;
