import React from 'react';

import { LocationData, DataType } from '../../Map/types';
import { dataTypes } from '../../Map/constants';
import * as Styled from './styles';

interface Props {
  dataType: DataType;
  country: LocationData;
  onClick: (countryId: string) => void;
}

export const CountryListItem: React.FC<Props> = ({
  dataType,
  country,
  onClick,
}) => {
  const listItemClick = () => onClick(country.location_id);

  return (
    <Styled.CountryListItem>
      <Styled.CountryButton onClick={listItemClick}>
        <Styled.CountryName>{country.location_name}</Styled.CountryName>
        <Styled.CountryCount>
          {country[dataType]}
          {dataTypes[dataType].dataSuffix && dataTypes[dataType].dataSuffix}
        </Styled.CountryCount>
      </Styled.CountryButton>
    </Styled.CountryListItem>
  );
};
