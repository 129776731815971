import React, { useEffect, useState } from 'react';
import axios from 'axios';

import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Legend } from '../Legend';
import { Loader } from '../Loader';
import { Sidebar } from '../Sidebar';
import { flyToCountry } from './helpers';
import { DataType } from './types';
import { useChoropleth } from './useChoropleth';
import { mapConfig, LOCATIONS_CASE_COUNTS_API } from './constants';
import * as Styled from './styles';

export const MapboxMap: React.FC = () => {
  const [map, setMap] = useState<Map>();
  const [loading, setLoading] = useState<boolean>();

  const [countriesData, setCountriesData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [dataType, setDataType] = useState(
    DataType.CONFIRMED
  );

  useChoropleth(countriesData, statesData, dataType, map);

  const sidebarListItemClick = (countryId: string) => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    flyToCountry(countryId, map);
  };

  const toggleDataType = (dataType: DataType) => {
    setDataType(dataType);
  };

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';
    if (!map) {
      const mapboxMap = new mapboxgl.Map(mapConfig);
      mapboxMap.addControl(new mapboxgl.NavigationControl());
      mapboxMap.on('load', () => setMap(mapboxMap));
    }
    if (countriesData?.length === 0) {
      fetchLocationsData();
    }
  }, []);

  const fetchLocationsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(LOCATIONS_CASE_COUNTS_API, {
        responseType: 'json',
      });
      setCountriesData(response.data.countries);
      setStatesData(response.data.US_states);
    } catch (error) {
      // TODO: snackbar messaging to user
      console.log(
        'There was an error getting data, please try again in a few minutes.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Styled.Map>
        <Legend dataType={dataType} />
      </Styled.Map>
      <Sidebar
        dataType={dataType}
        onToggleDataType={toggleDataType}
        onListItemClick={sidebarListItemClick}
        countriesData={countriesData}
      />
    </div>
  );
};
