export const countryGeoData = [
  {id: "AO", name: "Angola", boundingBoxes: [[11.7431,-18.0197,24.0467,-4.4289]], centroid: [-11.202692,17.873887]},
  {id: "BF", name: "Burkina Faso", boundingBoxes: [[-5.5235,9.4247,2.3892,15.0779]], centroid: [12.238333,-1.561593]},
  {id: "BI", name: "Burundi", boundingBoxes: [[29.0142,-4.4559,30.8114,-2.313]], centroid: [-3.373056,29.918886]},
  {id: "BJ", name: "Benin", boundingBoxes: [[0.7634,6.2168,3.8345,12.3838]], centroid: [9.30769,2.315834]},
  {id: "BV", name: "Bouvet Island", boundingBoxes: [[]]},
  {id: "BW", name: "Botswana", boundingBoxes: [[19.9773,-26.8542,29.3648,-17.7876]], centroid: [-22.328474,24.684866]},
  {id: "CD", name: "Democratic Republic Of The Congo", boundingBoxes: [[12.2137,-13.4538,31.274,5.3121]]},
  {id: "CF", name: "Central African Republic", boundingBoxes: [[14.4312,2.2701,27.4033,10.9962]], centroid: [6.611111,20.939444]},
  {id: "CG", name: "Republic of Congo", boundingBoxes: [[11.1302,-5.0043,18.6222,3.6873]]},
  {id: "CI", name: "Cote d'Ivoire", boundingBoxes: [[-8.6036,4.3513,-2.5059,10.7241]]},
  {id: "CM", name: "Cameroon", boundingBoxes: [[8.5328,1.6762,16.1834,13.0785]], centroid: [7.369722,12.354722]},
  {id: "CV", name: "Cabo Verde", boundingBoxes: [[-25.3416,14.8182,-22.6819,17.1937]]},
  {id: "DJ", name: "Djibouti", boundingBoxes: [[41.7646,10.941,43.4098,12.7086]], centroid: [11.825138,42.590275]},
  {id: "DZ", name: "Algeria", boundingBoxes: [[-8.6833,18.9866,11.9679,37.0924]], centroid: [28.033886,1.659626]},
  {id: "EG", name: "Egypt", boundingBoxes: [[24.7032,21.9949,36.8714,31.655]], centroid: [26.820553,30.802498]},
  {id: "EH", name: "Western Sahara", boundingBoxes: [[-17.0988,20.8062,-8.6821,27.6564]], centroid: [24.215527,-12.885834]},
  {id: "ER", name: "Eritrea", boundingBoxes: [[36.4268,12.3766,43.1167,18.0051]], centroid: [15.179384,39.782334]},
  {id: "ET", name: "Ethiopia", boundingBoxes: [[32.9989,3.4561,47.9782,14.8523]], centroid: [9.145,40.489673]},
  {id: "GA", name: "Gabon", boundingBoxes: [[8.7031,-3.9163,14.4806,2.3022]], centroid: [-0.803689,11.609444]},
  {id: "GH", name: "Ghana", boundingBoxes: [[-3.2439,4.7625,1.1872,11.1669]], centroid: [7.946527,-1.023194]},
  {id: "GM", name: "Gambia", boundingBoxes: [[-16.8248,13.0642,-13.8267,13.8121]], centroid: [13.443182,-15.310139]},
  {id: "GN", name: "Guinea", boundingBoxes: [[-15.0512,7.2159,-7.6812,12.6739]], centroid: [9.945587,-9.696645]},
  {id: "GQ", name: "Equatorial Guinea", boundingBoxes: [[8.4343,3.2171,8.9507,3.7583],[9.3859,0.9601,11.3354,2.3044]], centroid: [1.650801,10.267895]},
  {id: "GW", name: "Guinea-Bissau", boundingBoxes: [[-16.7118,10.9401,-13.6735,12.6799]]},
  {id: "HM", name: "Heard Island and McDonald Islands", boundingBoxes: [[73.2512,-53.1846,73.8378,-52.9663]]},
  {id: "KE", name: "Kenya", boundingBoxes: [[33.9,-4.6924,41.884,5.4923]], centroid: [-0.023559,37.906193]},
  {id: "KM", name: "Comoros", boundingBoxes: [[43.2267,-12.3683,44.5268,-11.3685]], centroid: [-11.875001,43.872219]},
  {id: "LR", name: "Liberia", boundingBoxes: [[-11.5075,4.3513,-7.3999,8.5377]], centroid: [6.428055,-9.429499]},
  {id: "LS", name: "Lesotho", boundingBoxes: [[27.0518,-30.6423,29.3907,-28.5817]], centroid: [-29.609988,28.233608]},
  {id: "LY", name: "Libya", boundingBoxes: [[9.3103,19.4966,25.1505,33.1819]], centroid: [26.3351,17.228331]},
  {id: "MA", name: "Morocco", boundingBoxes: [[-17.0031,21.4207,-1.0655,35.9299]], centroid: [31.791702,-7.09262]},
  {id: "MG", name: "Madagascar", boundingBoxes: [[43.2571,-25.5705,50.4827,-12.0796]], centroid: [-18.766947,46.869107]},
  {id: "ML", name: "Mali", boundingBoxes: [[-12.2806,10.1433,4.2347,24.9956]], centroid: [17.570692,-3.996166]},
  {id: "MR", name: "Mauritania", boundingBoxes: [[-17.064,14.7454,-4.8226,27.2859]], centroid: [21.00789,-10.940835]},
  {id: "MU", name: "Mauritius", boundingBoxes: [[57.3177,-20.5132,57.792,-19.9899]], centroid: [-20.348404,57.552152]},
  {id: "MW", name: "Malawi", boundingBoxes: [[32.6704,-17.1311,35.8928,-9.395]], centroid: [-13.254308,34.301525]},
  {id: "MZ", name: "Mozambique", boundingBoxes: [[30.2218,-26.8616,40.8445,-10.4644]], centroid: [-18.665695,35.529562]},
  {id: "NA", name: "Namibia", boundingBoxes: [[11.7217,-28.9388,25.2588,-16.9677]], centroid: [-22.95764,18.49041]},
  {id: "NE", name: "Niger", boundingBoxes: [[0.1639,11.6963,15.9632,23.5179]], centroid: [17.607789,8.081666]},
  {id: "NG", name: "Nigeria", boundingBoxes: [[2.686,4.2774,14.6271,13.8729]]},
  {id: "RE", name: "Reunion", boundingBoxes: [[55.2328,-21.369,55.8391,-20.8651]]},
  {id: "RW", name: "Rwanda", boundingBoxes: [[28.8576,-2.8086,30.8766,-1.0631]], centroid: [-1.940278,29.873888]},
  {id: "SC", name: "Seychelles", boundingBoxes: [[55.3834,-4.7855,55.543,-4.5588]], centroid: [-4.679574,55.491977]},
  {id: "SD", name: "Sudan", boundingBoxes: [[21.8253,8.6656,38.6095,22.2024]], centroid: [12.862807,30.217636]},
  {id: "SH", name: "Saint Helena, Ascension and Tristan da Cunha", boundingBoxes: [[-14.4149,-7.9758,-14.3025,-7.8826],[-5.7825,-16.004,-5.6597,-15.9062]]},
  {id: "SL", name: "Sierra Leone", boundingBoxes: [[-13.2927,6.9065,-10.2832,9.9965]], centroid: [8.460555,-11.779889]},
  {id: "SN", name: "Senegal", boundingBoxes: [[-17.5356,12.328,-11.3824,16.6789]], centroid: [14.497401,-14.452362]},
  {id: "SO", name: "Somalia", boundingBoxes: [[40.9645,-1.6953,51.3902,11.9837],[42.6564,7.9971,48.9386,11.4998]], centroid: [5.152149,46.199616]},
  {id: "ST", name: "Sao Tome and Principe", boundingBoxes: [[7.3307,1.5416,7.4523,1.6991],[6.4682,0.0474,6.75,0.4044]]},
  {id: "SZ", name: "Eswatini", boundingBoxes: [[30.7875,-27.31,32.1129,-25.743]], centroid: [-26.522503,31.465866]},
  {id: "TD", name: "Chad", boundingBoxes: [[13.4482,7.4753,23.9834,23.4452]], centroid: [15.454166,18.732207]},
  {id: "TF", name: "French Southern Territories", boundingBoxes: [[51.6593,-49.7099,70.5555,-46.3269]]},
  {id: "TG", name: "Togo", boundingBoxes: [[-0.0902,6.0894,1.7779,11.1156]], centroid: [8.619543,0.824782]},
  {id: "TN", name: "Tunisia", boundingBoxes: [[7.4956,30.2294,11.5359,37.3404]], centroid: [33.886917,9.537499]},
  {id: "TZ", name: "Tanzania", boundingBoxes: [[39.1823,-6.4537,39.871,-4.9062],[29.3234,-11.7162,40.4636,-0.9949]], centroid: [-6.369028,34.888822]},
  {id: "UG", name: "Uganda", boundingBoxes: [[29.5619,-1.4699,34.9782,4.2202]], centroid: [1.373333,32.290275]},
  {id: "YT", name: "Mayotte", boundingBoxes: [[45.0426,-12.985,45.2231,-12.653]], centroid: [-12.8275,45.166244]},
  {id: "ZA", name: "South Africa", boundingBoxes: [[16.4476,-34.7857,32.8861,-22.1463],[37.59,-46.9629,37.8877,-46.824]], centroid: [-30.559482,22.937506]},
  {id: "ZM", name: "Zambia", boundingBoxes: [[21.9789,-18.0415,33.6615,-8.1937]], centroid: [-13.133897,27.849332]},
  {id: "ZW", name: "Zimbabwe", boundingBoxes: [[25.224,-22.4021,33.0067,-15.6431]], centroid: [-19.015438,29.154857]},
  {id: "AD", name: "Andorra", boundingBoxes: [[1.4148,42.4345,1.7402,42.6427]], centroid: [42.546245,1.601554]},
  {id: "AL", name: "Albania", boundingBoxes: [[19.2807,39.6535,21.0311,42.6479]], centroid: [41.153332,20.168331]},
  {id: "AM", name: "Armenia", boundingBoxes: [[43.4395,38.869,46.5848,41.291]], centroid: [40.069099,45.038189]},
  {id: "AT", name: "Austria", boundingBoxes: [[9.524,46.3997,17.1474,49.0011]], centroid: [47.516231,14.550072]},
  {id: "AX", name: "Åland Islands", boundingBoxes: [[19.519,60.0117,20.6113,60.4058]]},
  {id: "AZ", name: "Azerbaijan", boundingBoxes: [[44.7683,38.3987,50.3659,41.891]], centroid: [40.143105,47.576927]},
  {id: "BA", name: "Bosnia And Herzegovina", boundingBoxes: [[16.2264,42.5597,19.5838,45.2766],[15.7366,42.5867,19.0412,45.2157]], centroid: [43.915886,17.679076]},
  {id: "BE", name: "Belgium", boundingBoxes: [[2.5249,50.6976,5.8925,51.4911],[2.8555,49.5109,6.3645,50.806],[4.2146,50.776,4.4417,50.9004]], centroid: [50.503887,4.469936]},
  {id: "BG", name: "Bulgaria", boundingBoxes: [[22.344,41.2436,28.5854,44.2378]], centroid: [42.733883,25.48583]},
  {id: "BY", name: "Belarus", boundingBoxes: [[23.1751,51.265,32.7103,56.1458]], centroid: [53.709807,27.953389]},
  {id: "CH", name: "Switzerland", boundingBoxes: [[5.97,45.83,10.4546,47.7756]], centroid: [46.818188,8.227512]},
  {id: "CY", name: "Cyprus", boundingBoxes: [[32.301,34.5696,34.0502,35.1827],[32.7127,35.0003,34.5561,35.6621]], centroid: [35.126413,33.429859]},
  {id: "CZ", name: "Czech Republic", boundingBoxes: [[12.0897,48.5762,18.8322,51.0378]], centroid: [49.817492,15.472962]},
  {id: "DE", name: "Germany", boundingBoxes: [[5.8575,47.2788,15.0166,55.0587]], centroid: [51.165691,10.451526]},
  {id: "DK", name: "Denmark", boundingBoxes: [[8.1215,54.6289,12.6657,57.7369],[14.6842,55.0049,15.1371,55.2967]], centroid: [56.26392,9.501785]},
  {id: "EE", name: "Estonia", boundingBoxes: [[21.8545,57.5255,28.1511,59.639]], centroid: [58.595272,25.013607]},
  {id: "ES", name: "Spain", boundingBoxes: [[-9.2356,36.0259,3.3067,43.7646],[-18.1605,27.6464,-13.4229,29.2372],[1.2233,38.6588,4.3221,40.0751]], centroid: [40.463667,-3.74922]},
  {id: "FI", name: "Finland", boundingBoxes: [[20.6222,59.816,31.5365,70.0648]], centroid: [61.92411,25.748151]},
  {id: "FO", name: "Faroe Islands", boundingBoxes: [[-7.4226,61.4143,-6.4061,62.3557]], centroid: [61.892635,-6.911806]},
  {id: "FR", name: "France", boundingBoxes: [[-4.7625,42.3405,8.1403,51.0971],[8.5656,41.3849,9.5564,43.0215]], centroid: [46.227638,2.213749]},
  {id: "GB", name: "United Kingdom", boundingBoxes: [[-5.6562,50.0214,1.7466,55.808],[-8.1448,54.0513,-5.4704,55.2418],[-5.2623,51.3904,-2.6623,53.4193],[-7.543,54.6895,-0.7743,60.8319]], centroid: [55.378051,-3.435973]},
  {id: "GE", name: "Georgia", boundingBoxes: [[39.9783,41.0702,46.6726,43.5698]], centroid: [42.315407,43.356892]},
  {id: "GG", name: "Guernsey", boundingBoxes: [[-2.6461,49.4287,-2.5123,49.5066]]},
  {id: "GR", name: "Greece", boundingBoxes: [[19.6465,34.9345,28.2318,41.7438]], centroid: [39.074208,21.824312]},
  {id: "HR", name: "Croatia", boundingBoxes: [[13.5172,42.4329,19.401,46.5346]], centroid: [45.1,15.2]},
  {id: "HU", name: "Hungary", boundingBoxes: [[16.0931,45.753,22.8767,48.5535]], centroid: [47.162494,19.503304]},
  {id: "IE", name: "Ireland", boundingBoxes: [[-10.3902,51.4737,-6.0274,55.3658]], centroid: [53.41291,-8.24389]},
  {id: "IM", name: "Isle of Man", boundingBoxes: [[-4.7854,54.0587,-4.338,54.4072]], centroid: [54.236107,-4.548056]},
  {id: "IS", name: "Iceland", boundingBoxes: [[-24.4757,63.4067,-13.5561,66.5261]], centroid: [64.963051,-19.020835]},
  {id: "IT", name: "Italy", boundingBoxes: [[6.6277,37.9391,18.4858,47.0821],[11.9364,36.746,12.0513,36.8431],[8.1809,38.9097,9.8053,41.2571],[12.4355,36.6878,15.6347,38.2959],[12.315,35.487,12.893,35.885]], centroid: [41.87194,12.56738]},
  {id: "JE", name: "Jersey", boundingBoxes: [[-2.2358,49.1698,-2.0099,49.2664]]},
  {id: "LI", name: "Liechtenstein", boundingBoxes: [[9.4795,47.0574,9.6105,47.2708]], centroid: [47.166,9.555373]},
  {id: "LT", name: "Lithuania", boundingBoxes: [[20.8998,53.893,26.7757,56.4112]], centroid: [55.169438,23.881275]},
  {id: "LU", name: "Luxembourg", boundingBoxes: [[5.725,49.4455,6.4938,50.1672]], centroid: [49.815273,6.129583]},
  {id: "LV", name: "Latvia", boundingBoxes: [[21.0149,55.6675,28.2021,58.0634]], centroid: [56.879635,24.603189]},
  {id: "MC", name: "Monaco", boundingBoxes: [[7.3777,43.7317,7.4387,43.7709]], centroid: [43.750298,7.412841]},
  {id: "MD", name: "Moldova", boundingBoxes: [[26.6189,45.4504,30.1311,48.4777]], centroid: [47.411631,28.369885]},
  {id: "ME", name: "Montenegro", boundingBoxes: [[18.4363,41.8691,20.3477,43.5423]], centroid: [42.708678,19.37439]},
  {id: "MK", name: "North Macedonia", boundingBoxes: [[20.4486,40.8499,23.0057,42.3582]], centroid: [41.608635,21.745275]},
  {id: "MT", name: "Malta", boundingBoxes: [[14.1804,35.8202,14.5662,36.0758]], centroid: [35.937496,14.375416]},
  {id: "NL", name: "Netherlands", boundingBoxes: [[3.133,50.75,7.217,53.683]], centroid: [52.132633,5.291266]},
  {id: "NO", name: "Norway", boundingBoxes: [[-9.0989,70.8327,-7.9788,71.1777],[4.799,58.0209,30.9606,71.1421]], centroid: [60.472024,8.468946]},
  {id: "PL", name: "Poland", boundingBoxes: [[14.1286,49.0208,24.1058,54.8382]], centroid: [51.919438,19.145136]},
  {id: "PT", name: "Portugal", boundingBoxes: [[-31.283,36.9416,-25.0273,39.5208],[-9.4797,37.0054,-6.2125,42.1374],[-17.3,32.4,-16.25,33.15]], centroid: [39.399872,-8.224454]},
  {id: "RO", name: "Romania", boundingBoxes: [[20.2418,43.6708,29.7059,48.2635]], centroid: [45.943161,24.96676]},
  {id: "RS", name: "Serbia", boundingBoxes: [[19.1185,42.2421,22.9769,45.0977],[18.8391,44.6326,21.5332,46.1692],[20.0295,41.8538,21.7529,43.2611]], centroid: [44.016521,21.005859]},
  {id: "SE", name: "Sweden", boundingBoxes: [[11.1472,55.3464,24.1555,69.0369]], centroid: [60.128161,18.643501]},
  {id: "SI", name: "Slovenia", boundingBoxes: [[13.3782,45.4284,16.5162,46.8633]], centroid: [46.151241,14.995463]},
  {id: "SJ", name: "Svalbard and Jan Mayen", boundingBoxes: [[10.5576,74.3521,33.6293,80.4778]]},
  {id: "SK", name: "Slovakia", boundingBoxes: [[16.8627,47.7634,22.5387,49.5977]], centroid: [48.669026,19.699024]},
  {id: "SM", name: "San Marino", boundingBoxes: [[12.3969,43.8941,12.5146,43.9897]], centroid: [43.94236,12.457777]},
  {id: "UA", name: "Ukraine", boundingBoxes: [[22.1318,44.3876,40.1283,52.3536]], centroid: [48.379433,31.16558]},
  {id: "VA", name: "Holy See", boundingBoxes: [[12.4275,41.8976,12.4392,41.9062]]},
  {id: "XK", name: "Kosovo", boundingBoxes: [[]]},
  {id: "AG", name: "Antigua and Barbuda", boundingBoxes: [[-61.8687,17.5487,-61.7471,17.7141],[-61.8871,16.9972,-61.686,17.1689]], centroid: [17.060816,-61.796428]},
  {id: "AI", name: "Anguilla", boundingBoxes: [[-63.16,18.1714,-62.9796,18.2697]], centroid: [18.220554,-63.068615]},
  {id: "AW", name: "Aruba", boundingBoxes: [[-70.0661,12.423,-69.8957,12.6141]], centroid: [12.52111,-69.968338]},
  {id: "BB", name: "Barbados", boundingBoxes: [[-59.6467,13.0622,-59.4276,13.3177]], centroid: [13.193887,-59.543198]},
  {id: "BL", name: "Saint Barthélemy", boundingBoxes: [[-62.8754,17.8752,-62.7997,17.9223]]},
  {id: "BM", name: "Bermuda", boundingBoxes: [[-64.8628,32.2596,-64.6683,32.3869]], centroid: [32.321384,-64.75737]},
  {id: "BQ", name: "Bonaire, Sint Eustatius and Saba", boundingBoxes: [[-68.3711,12.0321,-68.2058,12.302]]},
  {id: "BS", name: "Bahamas", boundingBoxes: [[-78.9856,20.9374,-72.7473,26.9401]], centroid: [25.03428,-77.39628]},
  {id: "BZ", name: "Belize", boundingBoxes: [[-89.2375,15.8887,-87.7886,18.4823]], centroid: [17.189877,-88.49765]},
  {id: "CA", name: "Canada", boundingBoxes: [[-141.0021,41.6749,-52.6537,83.1161]], centroid: [56.130366,-106.346771]},
  {id: "CR", name: "Costa Rica", boundingBoxes: [[-85.908,8.0707,-82.5636,11.1895]], centroid: [9.748917,-83.753428]},
  {id: "CU", name: "Cuba", boundingBoxes: [[-84.8872,19.8555,-74.1368,23.1904]], centroid: [21.521757,-77.781167]},
  {id: "DM", name: "Dominica", boundingBoxes: [[-61.4812,15.2273,-61.2511,15.6331]], centroid: [15.414999,-61.370976]},
  {id: "DO", name: "Dominican Republic", boundingBoxes: [[-72.0004,17.6356,-68.3392,19.914]]},
  {id: "GD", name: "Grenada", boundingBoxes: [[-61.7822,12.0084,-61.607,12.237]], centroid: [12.262776,-61.604171]},
  {id: "GL", name: "Greenland", boundingBoxes: [[-72.8181,59.8155,-11.4255,83.5996]], centroid: [71.706936,-42.604303]},
  {id: "GP", name: "Guadeloupe", boundingBoxes: [[-61.7941,15.886,-61.1726,16.5066]], centroid: [16.995971,-62.067641]},
  {id: "GT", name: "Guatemala", boundingBoxes: [[-92.2352,13.7365,-88.2283,17.8164]], centroid: [15.783471,-90.230759]},
  {id: "HN", name: "Honduras", boundingBoxes: [[-89.3626,12.9792,-83.1575,16.514]], centroid: [15.199999,-86.241905]},
  {id: "HT", name: "Haiti", boundingBoxes: [[-74.4781,18.0392,-71.6453,20.0937]], centroid: [18.971187,-72.285215]},
  {id: "JM", name: "Jamaica", boundingBoxes: [[-78.3395,17.7149,-76.2108,18.5222]], centroid: [18.109581,-77.297508]},
  {id: "KN", name: "Saint Kitts and Nevis", boundingBoxes: [[-62.8405,17.1006,-62.5322,17.4026]], centroid: [17.357822,-62.782998]},
  {id: "KY", name: "Cayman Islands", boundingBoxes: [[-81.4191,19.2719,-79.7423,19.7657]], centroid: [19.513469,-80.566956]},
  {id: "LC", name: "Saint Lucia", boundingBoxes: [[-61.0731,13.7176,-60.8868,14.0934]], centroid: [13.909444,-60.978893]},
  {id: "MF", name: "Saint Martin (French part)", boundingBoxes: [[-63.123,18.0689,-63.0094,18.1153]]},
  {id: "MQ", name: "Martinique", boundingBoxes: [[-61.2197,14.4263,-60.8263,14.8753]], centroid: [14.641528,-61.024174]},
  {id: "MS", name: "Montserrat", boundingBoxes: [[-62.223,16.6812,-62.1484,16.8096]], centroid: [16.742498,-62.187366]},
  {id: "MX", name: "Mexico", boundingBoxes: [[-118.4014,14.5454,-86.6963,32.7153]], centroid: [23.634501,-102.552784]},
  {id: "NI", name: "Nicaragua", boundingBoxes: [[-87.6702,10.7354,-83.1575,15.0081]], centroid: [12.865416,-85.207229]},
  {id: "PA", name: "Panama", boundingBoxes: [[-83.0273,7.2201,-77.196,9.5979]], centroid: [8.537981,-80.782127]},
  {id: "PM", name: "Saint Pierre and Miquelon", boundingBoxes: [[-56.3869,46.7528,-56.1374,47.099]], centroid: [46.941936,-56.27111]},
  {id: "PR", name: "Puerto Rico", boundingBoxes: [[-67.9371,17.9473,-65.2949,18.5222]], centroid: [18.220833,-66.590149]},
  {id: "SX", name: "Sint Maarten (Dutch part)", boundingBoxes: [[-63.1247,18.0192,-63.0112,18.0689]]},
  {id: "TC", name: "Turks and Caicos Islands", boundingBoxes: [[-72.3424,21.7517,-71.6369,21.9519]], centroid: [21.694025,-71.797928]},
  {id: "TT", name: "Trinidad and Tobago", boundingBoxes: [[-61.9061,10.0646,-60.9176,10.8402],[-60.8106,11.1686,-60.5255,11.3254]], centroid: [10.691803,-61.222503]},
  {id: "US", name: "United States", boundingBoxes: [[-124.71,24.5423,-66.987,49.3697],[-160.2435,18.9639,-154.8042,22.2231],[172.4948,51.3722,179.78,53.013],[-178.1945,51.6037,-130.0141,71.4077]], centroid: [37.09024,-95.712891]},
  {id: "VC", name: "Saint Vincent And The Grenadines", boundingBoxes: [[-61.3535,12.6947,-61.124,13.3587]], centroid: [12.984305,-61.287228]},
  {id: "VG", name: "Virgin Islands (British)", boundingBoxes: [[-64.6951,18.3991,-64.2736,18.7527]]},
  {id: "VI", name: "Virgin Islands (U.S.)", boundingBoxes: [[-65.0236,17.7017,-64.5805,18.3852]]},
  {id: "AS", name: "American Samoa", boundingBoxes: [[-170.8205,-14.3598,-170.5681,-14.2574]], centroid: [-14.270972,-170.132217]},
  {id: "AU", name: "Australia", boundingBoxes: [[112.9082,-39.1455,153.6169,-10.0518],[158.8359,-54.7492,158.9589,-54.4724],[143.8386,-43.6193,148.4742,-39.5802],[123.5725,-12.4359,123.5952,-12.4239]], centroid: [-25.274398,133.775136]},
  {id: "CK", name: "Cook Islands", boundingBoxes: [[-159.8425,-21.2495,-159.7369,-21.1864]], centroid: [-21.236736,-159.777671]},
  {id: "FJ", name: "Fiji", boundingBoxes: [[174.5872,-21.7059,180.0,-12.477],[-180.0,-20.6705,-178.2511,-16.1261]], centroid: [-16.578193,179.414413]},
  {id: "FM", name: "Micronesia (Federated States of)", boundingBoxes: [[138.0619,5.2772,162.9935,9.5933]], centroid: [7.425554,150.550812]},
  {id: "GU", name: "Guam", boundingBoxes: [[144.6493,13.2575,144.9408,13.6224]], centroid: [13.444304,144.793731]},
  {id: "KI", name: "Kiribati", boundingBoxes: [[169.5229,-1.2634,174.7789,3.1488],[-174.5408,-11.4568,-151.7826,3.9235]], centroid: [-3.370417,-168.734039]},
  {id: "MH", name: "Marshall Islands", boundingBoxes: [[166.8447,5.7998,171.7568,11.1687]], centroid: [7.131474,171.184478]},
  {id: "MP", name: "Northern Mariana Islands", boundingBoxes: [[145.1521,14.1113,145.8354,18.8068]], centroid: [17.33083,145.38469]},
  {id: "NC", name: "New Caledonia", boundingBoxes: [[159.9282,-22.6611,168.1391,-19.1146]], centroid: [-20.904305,165.618042]},
  {id: "NF", name: "Norfolk Island", boundingBoxes: [[167.9062,-29.0963,167.9904,-29.014]]},
  {id: "NR", name: "Nauru", boundingBoxes: [[166.907,-0.5508,166.9584,-0.4894]], centroid: [-0.522778,166.931503]},
  {id: "NU", name: "Niue", boundingBoxes: [[-169.9483,-19.1379,-169.7934,-18.966]], centroid: [-19.054445,-169.867233]},
  {id: "NZ", name: "New Zealand", boundingBoxes: [[-176.8477,-44.3306,-176.1226,-43.7176],[166.4776,-47.2637,174.3701,-40.49],[172.706,-41.6106,178.5362,-34.4291],[165.8892,-52.5703,169.2335,-50.531]], centroid: [-40.900557,174.885971]},
  {id: "PF", name: "French Polynesia", boundingBoxes: [[-151.5124,-20.8759,-136.2939,-8.7815]], centroid: [-17.679742,-149.406843]},
  {id: "PG", name: "Papua New Guinea", boundingBoxes: [[140.8623,-11.6306,154.2808,-1.3532],[154.54,-6.8628,155.9576,-5.0139]], centroid: [-6.314993,143.95555]},
  {id: "PN", name: "Pitcairn", boundingBoxes: [[-128.3502,-24.4126,-128.2901,-24.3232]]},
  {id: "PW", name: "Palau", boundingBoxes: [[131.135,3.0219,134.6596,7.7121]], centroid: [7.51498,134.58252]},
  {id: "SB", name: "Solomon Islands", boundingBoxes: [[155.6775,-11.8322,166.9292,-6.6089]], centroid: [-9.64571,160.156194]},
  {id: "TK", name: "Tokelau", boundingBoxes: [[-172.4987,-9.3583,-171.1864,-8.5465]], centroid: [-8.967363,-171.855881]},
  {id: "TO", name: "Tonga", boundingBoxes: [[-175.3624,-21.4506,-173.9219,-18.5653]], centroid: [-21.178986,-175.198242]},
  {id: "VU", name: "Vanuatu", boundingBoxes: [[166.5261,-20.2418,169.8963,-13.7095]], centroid: [-15.376706,166.959158]},
  {id: "WF", name: "Wallis and Futuna", boundingBoxes: [[-178.1944,-14.3249,-176.1281,-13.2217]], centroid: [-13.768752,-177.156097]},
  {id: "WS", name: "Samoa", boundingBoxes: [[-172.7785,-14.0473,-171.4496,-13.4652]]},
  {id: "AQ", name: "Antarctica", boundingBoxes: [[-180.0,-89.9989,-0.1846,-61.0727],[-90.6521,-68.8032,-90.5147,-68.7121],[0.0,-89.9989,180.0,-65.1297],[-45.9563,-60.733,-45.1729,-60.5209]]},
  {id: "AR", name: "Argentina", boundingBoxes: [[-73.5763,-55.0321,-53.6686,-21.8025]], centroid: [-38.416097,-63.616672]},
  {id: "BO", name: "Bolivia", boundingBoxes: [[-69.6457,-22.8917,-57.4957,-9.7104]], centroid: [-16.290154,-63.588653]},
  {id: "BR", name: "Brazil", boundingBoxes: [[-74.0021,-33.7422,-34.8055,5.258]], centroid: [-14.235004,-51.92528]},
  {id: "CL", name: "Chile", boundingBoxes: [[-75.7081,-55.8917,-66.4358,-17.5061],[-78.9895,-33.6678,-78.7689,-33.5752],[-109.4341,-27.1713,-109.2229,-27.0684]], centroid: [-35.675147,-71.542969]},
  {id: "CO", name: "Colombia", boundingBoxes: [[-79.0254,-4.2359,-66.876,12.4344],[-82.39,11.16,-79.6,15.33]], centroid: [4.570868,-74.297333]},
  {id: "EC", name: "Ecuador", boundingBoxes: [[-80.9628,-4.9906,-75.2496,1.4554],[-91.6542,-1.342,-89.2594,0.1258]], centroid: [-1.831239,-78.183406]},
  {id: "FK", name: "Falkland Islands [Malvinas]", boundingBoxes: [[-61.145,-52.308,-57.7918,-51.2699]]},
  {id: "GF", name: "French Guiana", boundingBoxes: [[-54.6163,2.121,-51.6525,5.7822]], centroid: [3.933889,-53.125782]},
  {id: "GS", name: "South Georgia and the South Sandwich Islands", boundingBoxes: [[-26.451,-58.4923,-26.2599,-58.3822],[-38.0174,-54.8668,-35.7986,-53.9841]]},
  {id: "GY", name: "Guyana", boundingBoxes: [[-61.3908,1.2012,-56.4828,8.5493]], centroid: [4.860416,-58.93018]},
  {id: "PE", name: "Peru", boundingBoxes: [[-81.3366,-18.3456,-68.6853,-0.0417]], centroid: [-9.189967,-75.015152]},
  {id: "PY", name: "Paraguay", boundingBoxes: [[-62.651,-27.5538,-54.2418,-19.2862]], centroid: [-23.442503,-58.443832]},
  {id: "SR", name: "Suriname", boundingBoxes: [[-58.0545,1.8422,-53.9905,5.9935]], centroid: [3.919305,-56.027783]},
  {id: "SV", name: "El Salvador", boundingBoxes: [[-90.1059,13.164,-87.7153,14.4311]], centroid: [13.794185,-88.89653]},
  {id: "UY", name: "Uruguay", boundingBoxes: [[-58.4381,-34.9328,-53.1256,-30.1011]], centroid: [-32.522779,-55.765835]},
  {id: "VE", name: "Venezuela", boundingBoxes: [[-73.3662,0.688,-59.8289,12.1779]], centroid: [6.42375,-66.58973]},
  {id: "AE", name: "United Arab Emirates", boundingBoxes: [[51.5684,22.6215,56.388,26.0682]], centroid: [23.424076,53.847818]},
  {id: "AF", name: "Afghanistan", boundingBoxes: [[60.4857,29.3919,74.8913,38.4564]], centroid: [33.93911,67.709953]},
  {id: "BD", name: "Bangladesh", boundingBoxes: [[88.0234,20.7904,92.6316,26.5715]], centroid: [23.684994,90.356331]},
  {id: "BH", name: "Bahrain", boundingBoxes: [[50.4524,25.8068,50.6175,26.2464]], centroid: [25.930414,50.637772]},
  {id: "BN", name: "Brunei Darussalam", boundingBoxes: [[114.0639,4.024,115.3268,5.0224]], centroid: [4.535277,114.727669]},
  {id: "BT", name: "Bhutan", boundingBoxes: [[88.7388,26.7016,92.0834,28.3112]], centroid: [27.514162,90.433601]},
  {id: "CC", name: "Cocos (Keeling) Islands", boundingBoxes: [[96.8259,-12.1998,96.9253,-12.1262]]},
  {id: "CN", name: "China", boundingBoxes: [[73.6073,20.2637,134.7523,53.5556],[108.6356,18.2183,111.0137,20.1377]], centroid: [35.86166,104.195397]},
  {id: "CX", name: "Christmas Island", boundingBoxes: [[105.5841,-10.5642,105.7254,-10.4307]]},
  {id: "HK", name: "Hong Kong", boundingBoxes: [[113.8389,22.1952,114.3353,22.565]], centroid: [22.396428,114.109497]},
  {id: "ID", name: "Indonesia", boundingBoxes: [[95.2066,-10.9097,140.9762,5.907]], centroid: [-0.789275,113.921327]},
  {id: "IL", name: "Israel", boundingBoxes: [[34.2453,29.4773,35.9135,33.4317]], centroid: [31.046051,34.851612]},
  {id: "IN", name: "India", boundingBoxes: [[68.165,8.0783,97.3436,35.4959],[72.7725,8.252,73.0836,11.2627],[92.3528,10.5208,93.0767,13.5455],[92.7133,6.7487,93.9296,9.2439]], centroid: [20.593684,78.96288]},
  {id: "IO", name: "British Indian Ocean Territory", boundingBoxes: [[72.3497,-7.4354,72.4985,-7.2204]]},
  {id: "IQ", name: "Iraq", boundingBoxes: [[38.7735,29.0637,48.5465,37.3719]], centroid: [33.223191,43.679291]},
  {id: "IR", name: "Iran", boundingBoxes: [[44.0232,25.1021,63.3052,39.7686]], centroid: [32.427908,53.688046]},
  {id: "JO", name: "Jordan", boundingBoxes: [[34.9508,29.1905,39.2928,33.3722]], centroid: [30.585164,36.238414]},
  {id: "JP", name: "Japan", boundingBoxes: [[130.8893,33.487,141.9932,41.5056],[128.6491,30.2414,141.3292,45.4655],[142.1071,26.6157,142.2021,26.7265],[139.7689,33.0455,139.8736,33.1292],[132.0326,32.752,134.7389,34.3584],[139.8209,41.4232,145.833,45.5095],[123.6798,24.2661,129.7146,28.5175],[129.5801,31.0151,132.0086,33.9278]], centroid: [36.204824,138.252924]},
  {id: "KG", name: "Kyrgyzstan", boundingBoxes: [[69.2291,39.2075,80.2462,43.2404]], centroid: [41.20438,74.766098]},
  {id: "KH", name: "Cambodia", boundingBoxes: [[102.3197,10.4112,107.6055,14.7051]], centroid: [12.565679,104.990963]},
  {id: "KP", name: "North Korea", boundingBoxes: [[124.3486,37.719,130.6873,42.9981]], centroid: [40.339852,127.510093]},
  {id: "KR", name: "South Korea", boundingBoxes: [[126.1656,33.2015,126.9313,33.5532],[126.0075,34.2964,129.5729,38.6234],[130.8103,37.4487,130.9343,37.5537]], centroid: [35.907757,127.766922]},
  {id: "KW", name: "Kuwait", boundingBoxes: [[46.5314,28.5332,48.4425,30.0973]], centroid: [29.31166,47.481766]},
  {id: "KZ", name: "Kazakhstan", boundingBoxes: [[46.6092,40.6086,87.3229,55.3896]], centroid: [48.019573,66.923684]},
  {id: "LA", name: "Lao People's Democratic Republic", boundingBoxes: [[100.1149,13.9212,107.6531,22.4953]]},
  {id: "LB", name: "Lebanon", boundingBoxes: [[35.1086,33.0757,36.585,34.6787]], centroid: [33.854721,35.862285]},
  {id: "LK", name: "Sri Lanka", boundingBoxes: [[79.7078,5.9494,81.877,9.8127]], centroid: [7.873054,80.771797]},
  {id: "MM", name: "Myanmar", boundingBoxes: [[92.1796,9.8754,101.1473,28.517]], centroid: [21.913965,95.956223]},
  {id: "MN", name: "Mongolia", boundingBoxes: [[87.7432,41.5955,119.8979,52.1173]], centroid: [46.862496,103.846656]},
  {id: "MO", name: "Macao", boundingBoxes: [[113.4789,22.1956,113.5481,22.2459]]},
  {id: "MV", name: "Maldives", boundingBoxes: [[73.382,3.2294,73.5283,4.2477]], centroid: [3.202778,73.22068]},
  {id: "MY", name: "Malaysia", boundingBoxes: [[99.6463,0.862,119.2663,7.3517]], centroid: [4.210484,101.975766]},
  {id: "NP", name: "Nepal", boundingBoxes: [[80.0517,26.3603,88.1615,30.3875]], centroid: [28.394857,84.124008]},
  {id: "OM", name: "Oman", boundingBoxes: [[51.9776,16.6484,59.8375,26.3563]], centroid: [21.512583,55.923255]},
  {id: "PH", name: "Philippines", boundingBoxes: [[116.9695,5.0602,126.5934,20.8413]], centroid: [12.879721,121.774017]},
  {id: "PK", name: "Pakistan", boundingBoxes: [[60.8434,23.7534,77.0486,37.0367]], centroid: [30.375321,69.345116]},
  {id: "PS", name: "Palestine", boundingBoxes: [[34.1981,31.2083,34.5256,31.5849],[34.8728,31.3513,35.5721,32.5344]], centroid: [31.952162,35.233154]},
  {id: "QA", name: "Qatar", boundingBoxes: [[50.7546,24.5646,51.6089,26.1533]], centroid: [25.354826,51.183884]},
  {id: "RU", name: "Russia", boundingBoxes: [[27.352,41.1993,68.9417,81.8542],[19.6044,54.3501,22.8313,55.2867],[-180.0,64.2797,-169.7292,71.5962],[52.7351,42.3025,180.0,81.2805]], centroid: [61.52401,105.318756]},
  {id: "SA", name: "Saudi Arabia", boundingBoxes: [[34.6162,16.3718,55.641,32.1245]], centroid: [23.885942,45.079162]},
  {id: "SG", name: "Singapore", boundingBoxes: [[103.6502,1.2654,103.9964,1.4471]], centroid: [1.352083,103.819836]},
  {id: "SY", name: "Syrian Arab Republic", boundingBoxes: [[35.7645,32.3173,42.3591,37.2973]], centroid: [34.802075,38.996815]},
  {id: "TH", name: "Thailand", boundingBoxes: [[97.3739,5.6368,105.641,20.4244]], centroid: [15.870032,100.992541]},
  {id: "TJ", name: "Tajikistan", boundingBoxes: [[67.3496,36.684,75.1188,41.0351]], centroid: [38.861034,71.276093]},
  {id: "TL", name: "Timor-Leste", boundingBoxes: [[124.0363,-9.4279,124.4444,-9.1903],[124.915,-9.5119,127.2961,-8.1399]]},
  {id: "TM", name: "Turkmenistan", boundingBoxes: [[52.4938,35.1708,66.6293,42.7785]], centroid: [38.969719,59.556278]},
  {id: "TR", name: "Turkey", boundingBoxes: [[25.6689,35.8314,44.8172,42.0933]], centroid: [38.963745,35.243322]},
  {id: "TW", name: "Taiwan", boundingBoxes: [[118.2873,21.925,121.929,25.2769]], centroid: [23.69781,120.960515]},
  {id: "UZ", name: "Uzbekistan", boundingBoxes: [[55.9757,37.1722,73.1369,45.5554]], centroid: [41.377491,64.585262]},
  {id: "VN", name: "Vietnam", boundingBoxes: [[102.1274,8.5833,109.4449,23.3452]], centroid: [14.058324,108.277199]},
  {id: "YE", name: "Yemen", boundingBoxes: [[42.549,12.6077,53.0856,18.9961],[53.3158,12.319,54.5111,12.7158]], centroid: [15.552727,48.516388]}
];
