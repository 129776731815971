import React from 'react';
import {
  GlobalHealthLogo,
  NewsNodesLogo,
  BNNewsLogo,
} from 'src/components/Logos';
import * as Styled from './styles';

export const Footer: React.FC = () => {
  return (
    <Styled.FooterContainer>
      <Styled.Label>In partnership with</Styled.Label>
      <GlobalHealthLogo />
      <BNNewsLogo />
      <NewsNodesLogo />
    </Styled.FooterContainer>
  );
};
