import styled, { keyframes } from 'styled-components';

const SpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderFullScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const LoaderBackground = styled(LoaderFullScreen)`
  background-color: #fff;
  opacity: .75;
  z-index: 2;
`;

export const LoaderContainer = styled(LoaderFullScreen)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

export const LoaderRing = styled.div`
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 36px;
    height: 36px;
    margin: 5px;
    border: 5px solid #0094e2;
    border-radius: 50%;
    animation: ${SpinAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0094e2 transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;
