import { useEffect } from 'react';
import { buildColorRange } from './helpers';
import { Map } from 'mapbox-gl';
import { mapSources, mapLayers } from './constants';
import { LocationData, DataType, LocationType } from './types';

export const useChoropleth = (
  countriesData: LocationData[],
  statesData: LocationData[],
  visibleDataType: DataType,
  map?: Map
): void => {

  useEffect(() => {
    if (!map || !countriesData.length) {
      return;
    }

    mapSources.forEach(({id, options}) => {
      if (!map.getSource(id)) {
        map.addSource(id, options);
      }
    });

    mapLayers.forEach(({
      id,
      dataType,
      locationType,
      matchExpressionLookupKey,
      matchExpressionDataKey,
    }) => {
      if (!map.getLayer(id)) {
        const dataSource = locationType == LocationType.COUNTRIES ? countriesData : statesData;
        const fillColors = buildColorRange(
          dataSource,
          dataType,
          matchExpressionLookupKey,
          matchExpressionDataKey
        );
        map.addLayer(
          {
            id,
            type: 'fill',
            source: locationType,
            layout: {
              visibility: dataType === visibleDataType ? 'visible' : 'none',
            },
            paint: {
              'fill-color': fillColors,
            },
            ...(locationType === LocationType.COUNTRIES && {'source-layer': 'country_boundaries'})
          },
          'admin-1-boundary-bg'
        );
      }
    });

    mapLayers.forEach(({ id, dataType }) => {
      map.setLayoutProperty(id, 'visibility', dataType === visibleDataType ? 'visible' : 'none');
    });

  }, [map, visibleDataType]);

};
