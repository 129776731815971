import { AnySourceData } from 'mapbox-gl';

export enum LocationType {
  COUNTRIES = 'countries',
  STATES = 'states',
}

export enum DataType {
  CONFIRMED = 'confirmed_cases',
  PREVALENCE = 'prevalence',
}

export interface DataRange {
  MIN: number;
  MAX?: number;
  LABEL: string;
  COLOR: string;
}

export type DataTypes = {
  [dataType in DataType]: {
    label: string;
    dataSuffix?: string;
    ranges: DataRange[];
  };
};

export interface LocationData {
  location_id: string;
  location_name: string;
  confirmed_cases: number;
  confirmed_deaths: number;
  prevalence: number;
  hospitalized: number;
  ICU: number;
  data_source: string;
}

export interface CountryGeoData {
  id: string;
  name: string;
  boundingBoxes: number[][];
  centroid?: number[];
}

export enum MatchExpressionLookupKey {
  COUNTRIES = 'iso_3166_1',
  STATES = 'STATE_NAME',
}

export enum MatchExpressionDataKey {
  COUNTRIES = 'location_id',
  STATES = 'location_name',
}

export interface MapSource {
  id: LocationType;
  options: AnySourceData;
}

export interface MapLayer {
  id: string;
  dataType: DataType;
  locationType: LocationType;
  matchExpressionLookupKey: MatchExpressionLookupKey,
  matchExpressionDataKey: MatchExpressionDataKey,
}
