import styled from 'styled-components';
import { footerHeight } from 'src/components/Footer/styles';

export const Map = styled.div.attrs({
  id: 'map',
})`
  position: relative;
  aspect-ratio: 1/1;
  width: 100vw;
  overflow: visible;
  @media (min-width: 768px) {
    height: calc(100vh - ${footerHeight});
  }
`;
