import React from 'react';
import * as Styled from './styles';

interface Props {
  color: string;
  label: string;
  suffix?: string;
}

export const LegendListItem: React.FC<Props> = ({color, label, suffix}) =>
  <Styled.ListItem>
    <Styled.Color color={color} />
    <Styled.Label>{label}{suffix}</Styled.Label>
  </Styled.ListItem>
;
