import styled from 'styled-components';
import healthMapLogoSrc from 'src/assets/healthmap-logo.svg';
import globalHealthLogoSrc from 'src/assets/global-health-logo.png';
import newsNodesLogoSrc from 'src/assets/news-nodes-logo.jpeg';
import bnNewsLogoSrc from 'src/assets/bn-news-logo.png';

export const HealthMapLogo = styled.img.attrs({
  src: healthMapLogoSrc,
  alt: 'Health Map logo',
})`
  width: 160px;
  margin-top: 20px;
`;

export const GlobalHealthLogo = styled.img.attrs({
  src: globalHealthLogoSrc,
  alt: 'Global Health logo',
})`
  width: 250px;
  height: 70px;
  @media (min-width: 768px) {
    width: 250px;
    height: 70px;
  }
`;

export const NewsNodesLogo = styled.img.attrs({
  src: newsNodesLogoSrc,
  alt: 'News Nodes logo',
})`
  width: 250px;
  height: 80px;
  @media (min-width: 768px) {
    width: 250px;
    height: 70px;
  }
`;

export const BNNewsLogo = styled.img.attrs({
  src: bnNewsLogoSrc,
  alt: 'BN News logo',
})`
  width: 110px;
  height: 100px;
  @media (min-width: 768px) {
    width: 90px;
    height: 70px;
  }
`;
