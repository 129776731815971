import styled from 'styled-components';

export const footerHeight = '75px';

export const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  > * {
    margin: 8px 0;
    max-height: 40px;
    width: auto;
  }
  > *:last-child {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    flex: 1 0 ${footerHeight};
    align-self: normal;
    flex-direction: row;
    justify-content: center;
    > *:not(:first-child) {
      margin: 0 30px;
      max-height: auto;
      width: auto;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
  @media (min-width: 768px) {
    font-weight: 400;
  }
`;
