import React from 'react';
import { dataTypes, NO_DATA_COLOR } from '../Map/constants';
import { LegendListItem } from './LegendListItem';
import { DataType } from '../Map/types';
import * as Styled from './styles';

interface Props {
  dataType: DataType;
}

export const Legend: React.FC<Props> = ({ dataType }) => {
  const noDataLabel = window.innerWidth <= 768 ? '0' : '0 or no data';

  return (
    <Styled.Legend>
      <Styled.Heading>{dataTypes[dataType].label}</Styled.Heading>
      <Styled.List data-test="map-legend">
        <LegendListItem color={NO_DATA_COLOR} label={noDataLabel} />
        {dataTypes[dataType].ranges.map((range, i) => (
          <LegendListItem key={i} color={range.COLOR} label={range.LABEL} suffix={dataTypes[dataType].dataSuffix} />
        ))}
      </Styled.List>
    </Styled.Legend>
  );
};
