import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  @media (min-width: 768px) {
    height: 100vh;
    overflow: hidden;
  }
`;

export const HomeLayout: React.FC = ({ children }) => {
  return <Layout>{children}</Layout>;
};
