import styled from 'styled-components';

interface Button {
  isActive: boolean;
}

export const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  border-radius: 0.2rem;
  background-color: #fff;
`;

export const ToggleButton = styled.button<Button>`
  flex: 1;
  padding: 0.5rem 0.75rem;
  text-align: left;
  border: 1px solid;
  color: ${props => (props.isActive ? '#fff' : 'inherit')};
  text-align: center;
  background-color: ${props => (props.isActive ? '#0094e2' : 'transparent')};
  border-color: ${props => (props.isActive ? '#0094e2' : '#ececec')};
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  text-transform: capitalize;
  & + & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;
